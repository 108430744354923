.grid {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 600px) 1fr; /* first column no more than 40%, ever (from 500px up) */
  min-height: 100px;
}

.grid .sidebar {
  /*grid-area: 2 / 1 / 2 / 1;*/
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  box-shadow: inset 0px 0px 15px 10px #c0536b;
}

@media all and (max-width: 500px) {
  /* screen width (or the .grid) needs to be at least 500px wide, in order to display the .sidebar, because min-width of 200px is exactly 40% of 500px, so display it when 500px and more, but hide it when less */
  .grid {
    grid-template-columns: 1fr;
  }
  .grid .sidebar {
    display: none;
  }
}
